import React, { useRef, useState } from 'react';
import MuxPlayer from '@mux/mux-player-react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute, faExpand } from '@fortawesome/free-solid-svg-icons';

interface VideoPlayerProps {
  defaultSrc: string;
}

// Styled components for overlay controls
const ControlsOverlay = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
`;

const ControlButton = styled.button`
  background: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  color: #228b22;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;

  &:hover {
    background: #f0f0f0;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; /* Fill the viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align video to the top */
  overflow: hidden;
`;

const StyledMuxPlayer = styled(MuxPlayer)`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures video covers container without black bars */
  display: flex;
`;

const SourceMenu = styled.div`
  position: absolute;
  top: 60px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 10;
`;

const SourceButton = styled.button<{ isActive: boolean }>`
  background: ${(props) => (props.isActive ? '#228b22' : 'white')};
  color: ${(props) => (props.isActive ? 'white' : '#228b22')};
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: ${(props) => (props.isActive ? '#1b6b18' : '#e0e0e0')};
  }
`;

const VideoPlayer: React.FC<VideoPlayerProps> = ({ defaultSrc }) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [src, setSrc] = useState(defaultSrc);
  const [activeSource, setActiveSource] = useState('SKY1');

  const sources = {
    SKY1: 'https://skylivetab-new.akamaized.net/hls/live/2038780/sky1/index_extreme.m3u8',
    SKY2: 'https://skylivetab-new.akamaized.net/hls/live/2038781/sky2/index_extreme.m3u8',
    'SKY Central': 'https://skylivetab-new.akamaized.net/hls/live/2038782/stcsd/index_veryhigh.m3u8',
  };

  // Fullscreen toggle function
  const toggleFullScreen = () => {
    if (playerRef.current) {
      if (!document.fullscreenElement) {
        playerRef.current.requestFullscreen().catch((err) => {
          console.error(`Error attempting to enable full-screen mode: ${err.message}`);
        });
      } else {
        document.exitFullscreen();
      }
    }
  };

  // Mute toggle function
  const toggleMute = () => {
    setIsMuted((prevMuted) => !prevMuted);
  };

  // Handle source change
  const changeSource = (sourceKey: keyof typeof sources) => {
    setSrc(sources[sourceKey]);
    setActiveSource(sourceKey);
  };

  return (
    <VideoContainer ref={playerRef}>
      {/* Controls overlay */}
      <ControlsOverlay>
        <ControlButton onClick={toggleFullScreen}>
          <FontAwesomeIcon icon={faExpand} />
        </ControlButton>
        <ControlButton onClick={toggleMute}>
          <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
        </ControlButton>
      </ControlsOverlay>

      {/* Source Menu */}
      <SourceMenu>
        {Object.keys(sources).map((sourceKey) => (
          <SourceButton
            key={sourceKey}
            isActive={activeSource === sourceKey}
            onClick={() => changeSource(sourceKey as keyof typeof sources)}
          >
            {sourceKey}
          </SourceButton>
        ))}
      </SourceMenu>

      {/* Video Player */}
      <StyledMuxPlayer
        src={src}
        streamType="live"
        autoPlay
        muted={isMuted}
      />
    </VideoContainer>
  );
};

export default VideoPlayer;
