// src/pages/AccountSettingsPage.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLink, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import ProviderCreatePage from './ProviderCreatePage';

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 1200px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

const Sidebar = styled.div`
    width: 220px;
    background-color: #222;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @media (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        overflow-x: auto;
        padding: 0;
        justify-content: space-around;
        border-radius: 0;
    }
`;

const TabButton = styled.button<{ active: boolean }>`
    display: flex;
    align-items: center;
    color: ${({ active }) => (active ? '#32cd32' : '#bbb')};
    background-color: ${({ active }) => (active ? '#333' : 'transparent')};
    border: none;
    text-align: left;
    padding: 15px 20px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 1rem;

    &:hover {
        background-color: #333;
    }

    svg {
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        padding: 10px;
        text-align: center;
        font-size: 0.9rem;
        flex-direction: column;

        svg {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
`;

const ContentArea = styled.div`
    flex-grow: 1;
    padding: 30px;
    overflow-y: auto;

    @media (max-width: 768px) {
        padding: 15px;
    }
`;

const Header = styled.h2`
    font-size: 1.5rem;
    color: #32cd32;
    margin-bottom: 20px;
    border-bottom: 2px solid #32cd32;
    padding-bottom: 10px;

    @media (max-width: 768px) {
        font-size: 1.2rem;
        text-align: center;
    }
`;

const ProvidersList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;

    @media (max-width: 768px) {
        align-items: center;
    }
`;

const ProviderItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }
`;

const ProviderInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
    }
`;

const ProviderImage = styled.img`
    width: 40px;
    height: 40px;

    @media (max-width: 768px) {
        width: 30px;
        height: 30px;
    }
`;

const ProviderStatus = styled.span<{ connected: boolean }>`
    color: ${({ connected }) => (connected ? '#32cd32' : '#ff4d4f')};
    font-weight: bold;
    margin-left: 10px;
`;

const ActionButtons = styled.div`
    display: flex;
    gap: 10px;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: space-between;
    }
`;

const AddProviderButton = styled.button`
    background-color: #32cd32;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #228b22;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
    }
`;

// Modal Styles
const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: white;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;



// Providers section component with Create, Edit, and Delete functionalities
const ProvidersSection: React.FC = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const openModal = (editMode = false) => {
        setModalOpen(true);
        setIsEditing(editMode);
    };
    const closeModal = () => setModalOpen(false);

    return (
        <ProvidersList>
            <ProviderItem>
                <ProviderInfo>
                    <ProviderImage src="https://www.tab.com.au/images/92d373e100a62206ed782a05653c96bd.tab-icon-new.svg" alt="TABCORPAU icon" />
                    <div>TABCORPAU</div>
                    <ProviderStatus connected={true}>Connected</ProviderStatus>
                </ProviderInfo>
                <ActionButtons>
                    <button onClick={() => openModal(true)}>Edit</button>
                    <button onClick={() => alert("Provider deleted!")}>Delete</button>
                </ActionButtons>
            </ProviderItem>
            <AddProviderButton onClick={() => openModal(false)}>+ Add Provider</AddProviderButton>

            {isModalOpen && (
                <ModalOverlay>
                    <ModalContent>
                        <ProviderCreatePage onClose={closeModal} />
                    </ModalContent>
                </ModalOverlay>
            )}
        </ProvidersList>
    );
};

// Main Account Settings Page Component
const AccountSettingsPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState('Providers');

    const renderContent = () => {
        switch (activeTab) {
            case 'Account Details':
                return <div>Manage your personal account details here.</div>;
            case 'Providers':
                return <ProvidersSection />;
            case 'Security':
                return <div>Security settings for your account.</div>;
            default:
                return null;
        }
    };

    return (
        <Container>
            <Sidebar>
                <TabButton active={activeTab === 'Account Details'} onClick={() => setActiveTab('Account Details')}>
                    <FontAwesomeIcon icon={faUser} />
                    Account Details
                </TabButton>
                <TabButton active={activeTab === 'Providers'} onClick={() => setActiveTab('Providers')}>
                    <FontAwesomeIcon icon={faLink} />
                    Providers
                </TabButton>
                <TabButton active={activeTab === 'Security'} onClick={() => setActiveTab('Security')}>
                    <FontAwesomeIcon icon={faShieldAlt} />
                    Security
                </TabButton>
            </Sidebar>
            <ContentArea>{renderContent()}</ContentArea>
        </Container>
    );
};

export default AccountSettingsPage;
