// src/pages/SignUpPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../Providers/authContext';
import {
    Background,
    AuthBox,
    Header,
    Title,
    Input,
    ActionButton,
    LinkContainer,
    SmallLink,
    ErrorMessage,
    FormGroup
} from '../Components/Auth/AuthStyles';

const SignupPage: React.FC = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { signup } = useAuth();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSignup = async () => {
        // Basic validation
        if (!formData.username || !formData.email || !formData.password) {
            setError('All fields are required');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await signup(formData.username, formData.email, formData.password);
            navigate('/dashboard');
        } catch (error) {
            console.error('Signup failed:', error);
            setError('Registration failed. Please try again.');
        }
    };

    return (
        <Background>
            <AuthBox>
                <Header>
                    <Title>Create Account</Title>
                </Header>

                <FormGroup>
                    <Input
                        type="text"
                        name="username"
                        placeholder="Username"
                        value={formData.username}
                        onChange={handleChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                </FormGroup>

                <FormGroup>
                    <Input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                    />
                </FormGroup>

                <ActionButton onClick={handleSignup}>
                    Create Account
                </ActionButton>

                <LinkContainer>
                    <SmallLink onClick={() => navigate('/login')}>
                        Already have an account? Sign In
                    </SmallLink>
                </LinkContainer>

                {error && (
                    <ErrorMessage>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        {error}
                    </ErrorMessage>
                )}
            </AuthBox>
        </Background>
    );
};

export default SignupPage;
