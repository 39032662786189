import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../Providers/authContext';

interface SecureRouteProps {
    requiredRole?: string;
    children: React.ReactNode;
}

const SecureRoute: React.FC<SecureRouteProps> = ({ requiredRole, children }) => {
    const { isAuthenticated, roles, loading } = useAuth();

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (requiredRole && !roles.includes(requiredRole)) {
        return <Navigate to="/" />;
    }

    return <>{children}</>;
};

export default SecureRoute;
