import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { AccountModel, CreateAccountModel, UpdateAccountModel, TokenResponseModel } from './apiTypes';
import { BASE_URL } from '../config';
import { RacePrediction } from '../Components/Racing/types';

class ApiService {


    private axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: BASE_URL,
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
        });

        this.axiosInstance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                const token = localStorage.getItem('accessToken');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
    }

    // Account Management Endpoints

    async getAccount(id: string): Promise<AccountModel> {
        const response = await this.axiosInstance.get(`/accounts/${id}`);
        return response.data;
    }

    async listAccounts(pageNumber = 1, pageSize = 10): Promise<AccountModel[]> {
        const response = await this.axiosInstance.get('/list', {
            params: { pageNumber, pageSize },
        });
        return response.data;
    }

    async createAccount(data: CreateAccountModel): Promise<AccountModel> {
        const response = await this.axiosInstance.post('/create', data);
        return response.data;
    }

    async updateAccount(id: string, data: UpdateAccountModel): Promise<AccountModel> {
        const response = await this.axiosInstance.patch(`/accounts/${id}/update`, data);
        return response.data;
    }

    async deleteAccount(id: string): Promise<void> {
        await this.axiosInstance.delete(`/accounts/${id}/delete`);
    }

    // Provider Management Endpoints

    async listProviders(
        accountId: string,
        search = '',
        pageNumber = 1,
        pageSize = 10
    ): Promise<any[]> {
        const response = await this.axiosInstance.get(`/accounts/${accountId}/providers`, {
            params: { search, pageNumber, pageSize },
        });
        return response.data;
    }

    async getProvider(accountId: string, providerId: string): Promise<any> {
        const response = await this.axiosInstance.get(`/accounts/${accountId}/providers/${providerId}`);
        return response.data;
    }

    // Authentication Endpoints

    async login(data: { username: string; password: string }): Promise<TokenResponseModel> {
        const response = await this.axiosInstance.post('/accounts/login', data);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return response.data;
    }

    async refreshToken(): Promise<TokenResponseModel> {
        const response = await this.axiosInstance.post('/accounts/refresh-token');
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return response.data;
    }

    async logout(): Promise<void> {
        await this.axiosInstance.post('/accounts/logout');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    async addRace(race: RacePrediction) {
        const response = await this.axiosInstance.post('/labs/punting/race-horsing/add', race);
        return response.data;
    }

    // Meeting Management Endpoints

    async listMeetings(
        search = '',
        pageNumber = 1,
        pageSize = 10,
        sortBy = 'MeetingName',
        activeOnly = true
    ): Promise<any[]> {
        const response = await this.axiosInstance.get('/meetings/list', {
            params: { search, pageNumber, pageSize, sortBy, activeOnly },
        });
        return response.data;
    }

    async getMeetingData(id: string): Promise<any> {
        const response = await this.axiosInstance.get(`/data/${id}`);
        return response.data;
    }

    async createMeeting(data: any): Promise<any> {
        const response = await this.axiosInstance.post('/data', data);
        return response.data;
    }

    async updateMeeting(id: string, data: any): Promise<any> {
        const response = await this.axiosInstance.put(`/data/${id}`, data);
        return response.data;
    }

    async deleteMeeting(id: string): Promise<void> {
        await this.axiosInstance.delete(`/data/${id}`);
    }

    // Predictions Endpoint

    async generateRacehorsePredictions(formData: FormData): Promise<any> {
        const response = await this.axiosInstance.post('/predictions/racehorses/generate', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
    }

    // New API Methods

    /**
     * Fetch available meeting dates based on jurisdiction.
     * @param jurisdiction - The selected jurisdiction (e.g., VIC).
     */
    async fetchMeetingDates(jurisdiction: string): Promise<any[]> {
        const response = await this.axiosInstance.get(
            `/api/meetings/dates?jurisdiction=${jurisdiction}`
        );
        return response.data;
    }

    /**
     * Fetch meetings based on selected date, jurisdiction, and race type.
     * @param meetingDate - The selected meeting date.
     * @param jurisdiction - The selected jurisdiction.
     * @param raceType - The selected race type (R/H/G).
     * @param pageNumber - The page number for pagination.
     * @param pageSize - The number of items per page.
     */
    async fetchMeetings(
        meetingDate: string,
        jurisdiction: string,
        raceType: string,
        pageNumber: number = 1,
        pageSize: number = 10
    ): Promise<any[]> {
        const response = await this.axiosInstance.get(
            `/api/meetings/${meetingDate}?jurisdiction=${jurisdiction}&pageNumber=${pageNumber}&pageSize=${pageSize}&type=${raceType}`
        );
        return response.data;
    }

    /**
     * Initialize bankroll with specified parameters.
     * @param initialBankroll - The initial bankroll amount.
     * @param profitThreshold - The profit threshold.
     * @param stopLossThreshold - The stop loss threshold.
     */
    async initializeBankroll(
        initialBankroll: number,
        profitThreshold: number,
        stopLossThreshold: number
    ): Promise<any> {
        const response = await this.axiosInstance.post(`/bankroll/initialize`, {
            initialBankroll,
            profitThreshold,
            stopLossThreshold,
        });
        return response.data;
    }

    /**
     * Calculate race predictions based on provided parameters.
     * @param jurisdiction - The jurisdiction.
     * @param meetingDate - The meeting date.
     * @param venue - The venue mnemonic.
     * @param raceType - The race type (R/H/G).
     * @param raceNumber - The race number.
     * @param bankrollId - The bankroll ID.
     */
    async calculateRace(
        jurisdiction: string,
        meetingDate: string,
        venue: string,
        raceType: string,
        raceNumber: number,
        bankrollId: string
    ): Promise<any> {
        const response = await this.axiosInstance.get(
            `/labs/punting/race-horsing/${jurisdiction}/${meetingDate}/${venue}/${raceType}/race/${raceNumber}/calculate/${bankrollId}`
        );
        return response.data;
    }

    async fetchMeetingDetails(
        meetingName: string,
        meetingDate: string,
        jurisdiction: string,
        raceType: string
    ) {
        const response = await this.axiosInstance.post(`/api/meetings/details`, {
            meetingName,
            meetingDate,
            jurisdiction,
            raceType,
        });
        return response.data;
    }

    // Betting Endpoint

    /**
     * Place bets using the specified bankroll ID and payload.
     * @param bankrollId - The bankroll ID.
     * @param payload - The bet placement payload.
     */
    async placeBets(
        bankrollId: string,
        payload: {
            jurisdiction: string;
            sellCode: {
                meetingCode: string;
                scheduledType: string;
            };
            venueMnemonic: string;
            raceNumber: number;
            runnerSelections: string; // e.g., "1" or "1,2"
            bets: {
                betType: string; // 'WIN' or 'PLACE'
                amount: number;
                runnerNumber: number;
                propositionId?: string;
                currentOdds?: number;
            }[];
        }
    ): Promise<any> {
        const response = await this.axiosInstance.post(`/bankroll/${bankrollId}/place`, payload);
        return response.data;
    }
}

const apiService = new ApiService();
export default apiService;