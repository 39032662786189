import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import { AuthProvider } from './Providers/authContext';
import MainRoutes from './MainRoutes';
import styled from 'styled-components';

const AppContainer = styled.div`
  margin: 0;
  width: 100%;
  min-height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContentArea = styled.main`
  flex: 1;
  padding: 60px 0 80px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: white;
`;

const App: React.FC = () => (
  <AuthProvider>
    <Router>
      <AppContainer>
        <ContentContainer>
          <Header />
          <MainContentArea>
            <MainRoutes />
          </MainContentArea>
          <Footer />
        </ContentContainer>
      </AppContainer>
    </Router>
  </AuthProvider>
);

export default App;
