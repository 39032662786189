import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #fff;
  &:hover {
    background-color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const PortalPage: React.FC = () => (
    <div>
        <h1>Home Page</h1>
        <StyledButton variant="contained">Styled Button</StyledButton>
    </div>
);

export default PortalPage;
