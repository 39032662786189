// src/pages/AccountPage.tsx
import React, { useEffect, useState } from 'react';
import authService from '../Services/authService';

const AccountPage: React.FC = () => {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        async function fetchAccount() {
            try {
                const response = await authService.getAxiosInstance().get('/list');
                setAccount(response.data);
            } catch (error) {
                console.error('Error fetching account:', error);
            }
        }
        fetchAccount();
    }, []);

    return <div>{account ? JSON.stringify(account) : 'Loading account data...'}</div>;
};

export default AccountPage;
