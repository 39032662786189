import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { differenceInMinutes, format, formatDistanceToNow } from 'date-fns';
import {
    FaChevronLeft,
    FaChevronRight,
    FaHorse,
    FaCalendarAlt,
    FaTrophy,
    FaCheckCircle,
    FaExchangeAlt,
    FaShieldAlt,
    FaStar,
    FaUser,
    FaUserFriends,
    FaMix,
    FaClock,
    FaShare,
    FaPlus,
} from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { WiDaySunny, WiCloudy } from 'react-icons/wi';
// Note: Replace '../../../Services/apiService' with your actual API service path
import apiService from '../../../Services/apiService';
import { BetRecommendation, Race } from '../../../Services/bettingService';
import { ShareableData } from '../types';

const Container = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 16px;
  background: #2c1f56;
  color: white;
`;

const HeaderTitle = styled.h1`
  font-size: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
`;

const FilterSection = styled.div`
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
`;

const DateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 12px;
  padding: 16px;
`;

const DateCard = styled.div<{ selected: boolean }>`
  background: ${({ selected }) => selected ? '#40B549' : 'white'};
  color: ${({ selected }) => selected ? 'white' : '#333'};
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  border: 1px solid ${({ selected }) => selected ? '#40B549' : '#e5e5e5'};
  transition: all 0.2s;

  &:hover {
    border-color: #40B549;
  }

  svg {
    font-size: 20px;
    margin-bottom: 8px;
    color: ${({ selected }) => selected ? 'white' : '#40B549'};
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
`;

const StepContent = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  padding: 0;
  margin-top: 16px;
  box-sizing: border-box;
`;

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  background: white;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    padding: 12px;
    .step-label {
      display: none;
    }
  }
`;

const StepItem = styled.div<{ active: boolean; clickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  padding: 5px 10px;

  .step-number {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background: ${({ active }) => (active ? '#40B549' : '#e0e0e0')};
    color: white;
  }

  .step-label {
    color: ${({ active }) => (active ? '#333' : '#666')};
    font-size: 13px;
  }
`;

const StepDivider = styled.div`
  flex: 0 0 auto;
  width: 20px;
  height: 1px;
  background: #e0e0e0;
`;

const StepDescription = styled.p`
  text-align: center;
  color: #666;
  margin: 8px 0;
  padding: 0 20px;
  font-size: 13px;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  min-width: 150px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: #666;
`;

const FilterSelect = styled.select`
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-width: 120px;

  &:focus {
    border-color: #40B549;
    outline: none;
  }
`;

const RaceTypeGroup = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const RaceTypeButton = styled.button<{ active: boolean }>`
  padding: 10px 20px;
  border: 2px solid ${({ active }) => (active ? '#40B549' : '#e0e0e0')};
  background: ${({ active }) =>
        active ? 'linear-gradient(135deg, #40B549 0%, #2D8A34 100%)' : 'white'};
  color: ${({ active }) => (active ? 'white' : '#666')};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  min-width: 120px;
  font-size: 14px;

  &:hover {
    border-color: #40B549;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

interface Prediction {
    runnerName: string;
    runnerNumber: number;
    prediction: string;
    ev: number;
    probabilityScore: number;
    currentOdds: number;
    kellyFraction: number;
    stabilityScore: number;
    propositionId?: string;
    recommendedStake?: number;
    potentialProfit?: number;
    silkUrl?: string;
    jockeyName?: string;
    speedRating?: number;
    averageFinishPosition?: number;
    consistencyScore?: number;
    classLevelFactor?: string;
    excluded?: boolean;
}

interface RacePrediction {
    meetingName: string;
    raceNumber: number;
    raceName: string;
    venueMnemonic: string;
    predictions: Prediction[] | null;
    startTime: string;
    raceDistance: number;
    error?: string;
    sellCode?: {
        meetingCode: string;
        scheduledType: string;
    };
    jurisdiction?: string;
    raceType?: string;
}

interface GroupedRacePredictions {
    [meetingName: string]: RacePrediction[];
}

interface StepInfo {
    label: string;
    description: string;
}

const STEPS: StepInfo[] = [
    {
        label: 'Select Date',
        description: 'Choose a race meeting date from the available options',
    },
    {
        label: 'Select Meetings',
        description: 'Select meetings and process race calculations',
    },
    {
        label: 'View Results',
        description: 'Review calculated race predictions',
    },
    {
        label: 'Finalize Bets',
        description: 'Finalize your selections and proceed',
    },
];

const NavigationBar = styled.div`
  position: fixed;
  bottom: 48px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: white;
  border-top: 1px solid #e5e5e5;
  z-index: 999;
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: white;
  border-top: 1px solid #eee;
  position: sticky;
  bottom: 0;
`;

const NavigationSteps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Button = styled.button<{ primary?: boolean }>`
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  min-width: 100px;
  justify-content: center;

  ${({ primary }) =>
        primary
            ? `
        background: #40B549;
        color: white;
        &:hover {
          background: #379940;
        }
      `
            : `
        background: #f5f5f5;
        color: #333;
        &:hover {
          background: #e0e0e0;
        }
      `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CancelButton = styled(Button)`
  margin-top: 20px;
  background: #ff4444;
  color: white;

  &:hover {
    background: #cc0000;
  }
`;

const RunnerCard = styled.div<{ isRecommended: boolean; rank?: number }>`
  position: relative;
  background: ${({ isRecommended, rank }) =>
        rank === 1 ? '#e8f5e9' : rank === 2 ? '#e3f2fd' : isRecommended ? '#e8f5e9' : 'white'};
  border: 1px solid ${({ rank }) => (rank === 1 ? '#4caf50' : rank === 2 ? '#2196f3' : '#ddd')};
  padding: 8px;
  margin: 4px 0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.9em;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);

    .bet-options {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const SilkImage = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  overflow: hidden;
  background: #f5f5f5;
  border: 1px solid #ddd;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const RunnerIndicators = styled.div`
  position: absolute;
  top: -8px;
  right: 40px; // Adjusted to not overlap with silk
  display: flex;
  gap: 2px;
  align-items: center;
  z-index: 2;
`;

const RunnerHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 48px; // Space for silk image
`;

const RunnerNumber = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #40B549 0%, #2D8A34 100%);
  border-radius: 50%;
  width: 24px; // Increased from 20px
  height: 24px; // Increased from 20px
  font-weight: 600;
  font-size: 12px;
  color: white;
  margin-right: 8px; // Add some space between number and name
`;

const RunnerName = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  display: inline; // Changed from flex to inline
`;

const JockeyInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  color: #666;
  margin-top: 2px;

  svg {
    color: #40B549;
    font-size: 10px;
  }
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 8px 0;
`;

const BetTypeTag = styled.span<{ type: string; highlighted?: boolean }>`
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  gap: 6px; // Increased gap between icon and text
  box-shadow: ${({ highlighted }) =>
        highlighted ? '0 2px 4px rgba(0,0,0,0.15)' : '0 1px 2px rgba(0,0,0,0.05)'};
  transform: ${({ highlighted }) => highlighted ? 'scale(1.05)' : 'scale(1)'};
  transition: all 0.2s ease;

  svg {
    margin-right: 2px; // Add small space after icon
  }

  ${({ type, highlighted }) => {
        switch (type) {
            case 'win':
                return highlighted
                    ? 'background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%); color: white; border: none;'
                    : 'background: #e8f5e9; color: #2e7d32; border: 1px solid #c8e6c9;';
            case 'place':
                return highlighted
                    ? 'background: linear-gradient(135deg, #2196F3 0%, #1976D2 100%); color: white; border: none;'
                    : 'background: #e3f2fd; color: #1565c0; border: 1px solid #bbdefb;';
            default:
                return 'background: #f5f5f5; color: #666; border: 1px solid #e0e0e0;';
        }
    }}
`;

const BetMetric = styled.div<{ highlighted?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  padding: 3px 6px;
  background: ${({ highlighted }) => highlighted ? '#f8f9fa' : 'transparent'};
  border-radius: 4px;

  span:first-child {
    color: #666;
    font-size: 10px;
    margin-right: 6px;
  }

  span:last-child {
    font-weight: ${({ highlighted }) => highlighted ? '700' : '600'};
    color: ${({ highlighted }) => highlighted ? '#2e7d32' : '#333'};
    min-width: 45px;
    text-align: right;
  }
`;

const BetDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
  margin-top: 45px; // Increased to avoid silk overlap
  margin-bottom: 24px; // Make space for bet options
  font-size: 0.85em;
`;

const BetCombination = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; // Increased gap between elements
  padding: 4px 8px;
  margin: 6px 0;
`;

const BetAmount = styled.span`
  margin-left: auto;
  font-weight: 500;
  white-space: nowrap;
  padding-left: 8px; // Add space before amount
`;

const BetRunners = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

const VenuePill = styled.div`
  background: #f0f0f0;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;

  @media (max-width: 768px) {
    font-size: 11px;
    padding: 3px 8px;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  overflow: hidden;
`;

const FilterBar = styled.div`
  padding: 15px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const FilterChip = styled.div<{ active: boolean }>`
  padding: 4px 8px;
  border-radius: 16px;
  background: ${({ active }) => (active ? '#40B549' : '#fff')};
  color: ${({ active }) => (active ? 'white' : '#666')};
  border: 1px solid ${({ active }) => (active ? '#40B549' : '#ddd')};
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s;

  &:hover {
    border-color: #40B549;
  }
`;

const BankrollSummary = styled.div`
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const ShareButton = styled(Button)`
    background: #4267B2;
    color: white;
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
        background: #365899;
    }

    svg {
        font-size: 16px;
    }
`;

const HorizontalTabLayout = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 12px;
  overflow: hidden;
`;

const HorizontalTabList = styled.div`
  display: flex;
  overflow-x: auto;
  background: white;
  border-bottom: 1px solid #eee;
  padding: 0 40px;
  scrollbar-width: none;
  position: relative;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  width: 100%;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const HorizontalTab = styled.button<{ active: boolean; completed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 25px;
  background: ${({ active }) =>
        active
            ? 'linear-gradient(90deg, #40B549 0%, #379940 100%)'
            : 'white'};
  border: none;
  color: ${({ active }) => (active ? '#fff' : '#666')};
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.3s ease;
  position: relative;
  text-align: center;
  min-width: 150px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: ${({ active }) =>
        active
            ? 'transparent'
            : 'linear-gradient(90deg, #40B549 0%, #379940 100%)'};
  }

  &:hover {
    background: ${({ active }) =>
        active
            ? 'linear-gradient(90deg, #40B549 0%, #379940 100%)'
            : '#f5f5f5'};
    color: ${({ active }) => (active ? '#fff' : '#333')};
  }

  span {
    margin: 0 auto;
  }
`;

const ScrollButton = styled.button<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #40B549;
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 11;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  &:hover {
    opacity: 1;
  }
`;

const TabListContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

const MeetingProgress = styled.div`
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;
`;

const ProgressBar = styled.div<{
    progress: number;
    status: 'not-started' | 'in-progress' | 'completed';
}>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background: ${({ status }) => {
        switch (status) {
            case 'completed':
                return 'linear-gradient(90deg, #4CAF50, #45a049)';
            case 'in-progress':
                return 'linear-gradient(90deg, #FF9800, #F57C00)';
            default:
                return 'linear-gradient(90deg, #9E9E9E, #757575)';
        }
    }};
  transition: width 0.3s ease;
`;

const TabContent = styled.div`
  padding: 12px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
`;

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
`;

const MeetingStats = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
`;


const RaceSummary = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
`;


const RaceCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const RaceHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  gap: 12px; // Add gap between elements
`;

const RaceTime = styled.div<{ startingSoon?: boolean }>`
  padding: 4px 12px;
  border-radius: 20px;
  background: ${({ startingSoon }) => (startingSoon ? '#fff3e0' : '#f5f5f5')};
  color: ${({ startingSoon }) => (startingSoon ? '#f57c00' : '#666')};
  font-weight: 500;
  margin-top: 10px;
`;

const RunnersGrid = styled.div`
  display: grid;
  gap: 12px;
  margin: 12px 0;
  padding: 0;
  width: 100%;

  @media (min-width: 2000px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (min-width: 1600px) and (max-width: 1999px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1200px) and (max-width: 1599px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 480px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 479px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const CollaborativeBetting = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  margin: 8px 0;
`;

const BetSplitOption = styled.div`
  margin: 12px 0; // Add more vertical space between friend sections

  strong {
    display: flex;
    align-items: center;
    gap: 8px; // Increased gap between icon and text
    margin-bottom: 8px;

    svg {
      margin-right: 2px; // Add small space after icon
    }
  }
`;

const BetActionBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  background: white;
  border-top: 1px solid #eee;
  justify-content: flex-end;
`;

const CopyButton = styled(Button)`
  background: #2c1f56;
  color: white;

  &:hover {
    background: #231840;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 4px solid rgba(64, 181, 73, 0.1);
  border-radius: 50%;
  border-top-color: #40B549;
  animation: spin 1s ease-in-out infinite;
  margin: 40px auto;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  padding: 40px;
  background: white;
  border-radius: 12px;
  margin: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  h3 {
    margin: 24px 0 12px;
    color: #2c1f56;
    font-weight: 600;
  }

  p {
    color: #666;
    font-size: 15px;
    max-width: 400px;
    line-height: 1.5;
  }
`;

interface BettingFilter {
    id: string;
    label: string;
    field: keyof Prediction;
    min?: number;
    max?: number;
    active: boolean;
}

interface BankrollStats {
    totalBets: number;
    totalCost: number;
    expectedValue: number;
    riskLevel: string;
}

const pulseAndGlow = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(64, 181, 73, 0.2);
  }
  70% {
    transform: scale(1.01);
    box-shadow: 0 0 0 10px rgba(64, 181, 73, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(64, 181, 73, 0);
  }
`;

const MeetingContainer = styled.div<{ isSelected: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 12px;
  align-items: center;
  padding: 16px;
  background: white;
  border: 1px solid ${({ isSelected }) => (isSelected ? '#40B549' : '#e0e0e0')};
  border-radius: 8px;
  margin: 8px 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  @media (max-width: 768px) {
    padding: 12px;
    margin: 8px;
  }

  &:hover {
    border-color: #40B549;
    transform: translateY(-1px);
  }
`;

const RaceIndicator = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 45px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 12px;
`;

const VenueCode = styled.div`
  background: #2c1f56;
  color: white;
  padding: 6px 8px;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.5px;
`;

const RacesRemaining = styled.div<{ progress: number }>`
  color: #666;
  padding: 2px 4px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  background: #f5f5f5;
`;

const RaceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 6px;
  margin-top: 4px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 4px;
  }
`;

const RaceItem = styled.div`
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 4px;
  background: #f5f5f5;
  padding: 4px 8px;
  border-radius: 12px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 11px;
    padding: 3px 6px;
  }
`;

const StatPill = styled.div<{ variant?: 'success' | 'warning' | 'info' | 'danger' }>`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;

  ${({ variant }) => {
        switch (variant) {
            case 'success':
                return 'background: #e8f5e9; color: #2e7d32;';
            case 'warning':
                return 'background: #fff3e0; color: #f57c00;';
            case 'info':
                return 'background: #e3f2fd; color: #1565c0;';
            case 'danger':
                return 'background: #ffebee; color: #c62828;';
            default:
                return 'background: #f5f5f5; color: #666;';
        }
    }}

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 3px 6px;
  }
`;

const MeetingName = styled.h3<{ isSelected: boolean }>`
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MeetingInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0; // Prevents flex items from overflowing
`;

const MeetingMetrics = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    gap: 4px;
  }
`;

// Helper function for human readable time
const getTimeDisplay = (raceTime: string) => {
    const diff = differenceInMinutes(new Date(raceTime), new Date());
    if (diff < 60) return `${diff}m`;
    return `${Math.floor(diff / 60)}hr`;
};

const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(amount);
};

const MeetingDateSelector = () => {
    // State variables
    const [jurisdiction, setJurisdiction] = useState<string>('VIC');
    const [raceType, setRaceType] = useState<string>('R');
    const [meetingDates, setMeetingDates] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [meetings, setMeetings] = useState<any[]>([]);
    const [selectedMeetings, setSelectedMeetings] = useState<string[]>([]); // Initialize as empty array
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [bankroll, setBankroll] = useState<{
        bankrollId: string;
        initialBankroll: number;
        currentAmount: number;
    } | null>(null);
    const [isBankrollInitialized, setIsBankrollInitialized] = useState<boolean>(false);
    const [racePredictions, setRacePredictions] = useState<GroupedRacePredictions>({});
    const [processingRaces, setProcessingRaces] = useState<boolean>(false);
    const [step, setStep] = useState<number>(1);
    const [progress, setProgress] = useState<number>(0);
    const [processedRaces, setProcessedRaces] = useState<number>(0);
    const [totalRaces, setTotalRaces] = useState<number>(0);
    const [currentRace, setCurrentRace] = useState<any>(null);
    const [activeFilters, setActiveFilters] = useState<BettingFilter[]>([
        { id: 'highEv', label: 'High EV (>2)', field: 'ev', min: 2, active: false },
        { id: 'stable', label: 'Stable (>8)', field: 'stabilityScore', min: 8, active: false },
        { id: 'highProb', label: 'High Probability (>0.3)', field: 'probabilityScore', min: 0.3, active: false },
    ]);
    const [activeMeetingTab, setActiveMeetingTab] = useState<string>('');
    const [bankrollStats, setBankrollStats] = useState<BankrollStats>({
        totalBets: 0,
        totalCost: 0,
        expectedValue: 0,
        riskLevel: 'Medium',
    });
    const [placedBets, setPlacedBets] = useState<{ [raceId: string]: boolean }>({});
    const [isCancelling, setIsCancelling] = useState(false);
    const [isLoadingMeetings, setIsLoadingMeetings] = useState(false);
    const [showLeftScroll, setShowLeftScroll] = useState(false);
    const [showRightScroll, setShowRightScroll] = useState(false);
    const [displayedMeetings, setDisplayedMeetings] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const meetingsPerPage = 9;
    const [showCompletion, setShowCompletion] = useState(false);

    useEffect(() => {
        const savedBankroll = localStorage.getItem('bankroll');
        if (savedBankroll) {
            setBankroll(JSON.parse(savedBankroll));
            setIsBankrollInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (bankroll) {
            localStorage.setItem('bankroll', JSON.stringify(bankroll));
        }
    }, [bankroll]);

    useEffect(() => {
        const fetchDates = async () => {
            try {
                const data = await apiService.fetchMeetingDates(jurisdiction);
                setMeetingDates(data);
            } catch (error) {
                console.error('Error fetching meeting dates:', error);
            }
        };
        fetchDates();
    }, [jurisdiction]);

    const initializeBankroll = async () => {
        try {
            const response = await apiService.initializeBankroll(200, 150, 50);
            const initialBankroll = {
                bankrollId: response.bankrollId,
                initialBankroll: response.initialBankroll,
                currentAmount: response.initialBankroll,
            };
            setBankroll(initialBankroll);
            localStorage.setItem('bankroll', JSON.stringify(initialBankroll));
            setIsBankrollInitialized(true);
        } catch (error) {
            console.error('Error initializing bankroll:', error);
        }
    };

    const fetchMeetings = async () => {
        const allMeetings = [];
        let page = 1;
        const pageSize = 50;

        try {
            while (true) {
                const data = await apiService.fetchMeetings(selectedDate, jurisdiction, raceType, page, pageSize);

                if (!data || data.length === 0) {
                    break;
                }

                // Filter by race type only
                const activeMeetings = data.filter((meeting: any) => {
                    // Check if meeting type matches
                    if (meeting.raceType !== raceType) {
                        return false;
                    }

                    // Ensure races array exists
                    if (!Array.isArray(meeting.races) || meeting.races.length === 0) {
                        return false;
                    }

                    return true;
                });

                allMeetings.push(...activeMeetings);
                page += 1;
            }

            setMeetings(allMeetings);
            setCurrentPage(0);
        } catch (error) {
            console.error('Error fetching meetings:', error);
        }
    };

    const handleMeetingSelect = (meetingName: string) => {
        setSelectedMeetings((prev) => {
            if (!prev) return [meetingName];
            return prev.includes(meetingName)
                ? prev.filter((name) => name !== meetingName)
                : [...prev, meetingName];
        });
    };

    const processRace = async (race: any) => {
        if (!bankroll || !bankroll.bankrollId) {
            throw new Error('Bankroll not initialized');
        }

        try {
            const prediction = await apiService.calculateRace(
                jurisdiction,
                race.meetingDate,
                race.venueMnemonic,
                race.raceType,
                race.raceNumber,
                bankroll.bankrollId
            );
            // Add sellCode and jurisdiction to race data
            return {
                ...race,
                predictions: prediction.predictions,
                sellCode: {
                    meetingCode: getVenueCode(race.venueMnemonic),
                    scheduledType: race.raceType,
                },
                jurisdiction: jurisdiction,
            };
        } catch (error) {
            console.error(`Error processing race ${race.raceNumber} at ${race.meetingName}:`, error);
            return {
                ...race,
                predictions: null,
                error: 'Failed to process race',
            };
        }
    };

    const handleProcessRaces = async () => {
        if (!bankroll || !bankroll.bankrollId || !selectedMeetings) {
            console.error('Bankroll not initialized or no meetings selected');
            alert('Please select meetings and ensure bankroll is initialized');
            return;
        }

        setProcessingRaces(true);
        setProgress(0);

        try {
            // Get all races from selected meetings
            const selectedRaces = meetings
                .filter((meeting) => selectedMeetings.includes(meeting.meetingName))
                .flatMap((meeting) =>
                    meeting.races.map((race: any) => ({
                        meetingName: meeting.meetingName,
                        raceNumber: race.raceNumber,
                        raceName: race.raceName,
                        venueMnemonic: meeting.venueMnemonic,
                        meetingDate: meeting.meetingDate,
                        raceType: raceType,
                        startTime: race.raceStartTime,
                        raceDistance: race.raceDistance,
                        raceStatus: race.raceStatus,
                        hasFixedOdds: race.hasFixedOdds,
                        broadcastChannel: race.broadcastChannel,
                    }))
                );

            setTotalRaces(selectedRaces.length);
            setProcessedRaces(0);

            // Process races in batches to limit concurrency
            const batchSize = 5;
            const results: any[] = [];
            for (let i = 0; i < selectedRaces.length; i += batchSize) {
                const batch = selectedRaces.slice(i, i + batchSize);
                const batchPromises = batch.map((race) => processRace(race));
                const batchResults = await Promise.all(batchPromises);
                results.push(...batchResults);

                setProcessedRaces((prev) => prev + batchResults.length);
                setProgress(((i + batchResults.length) / selectedRaces.length) * 100);
            }

            // Group results by meeting
            const groupedResults = results.reduce<GroupedRacePredictions>((acc, race) => {
                if (!acc[race.meetingName]) {
                    acc[race.meetingName] = [];
                }

                acc[race.meetingName].push(race);
                return acc;
            }, {});

            setRacePredictions(groupedResults);
            setProcessingRaces(false);
            setCurrentRace(null); // Clear current race when done
            setActiveMeetingTab(Object.keys(groupedResults)[0]);
        } catch (error) {
            console.error('Error processing races:', error);
            setProcessingRaces(false);
            setCurrentRace(null); // Clear current race on error
        } finally {
            setStep(3); // Move to the new results step
        }
    };

    const handleNext = async () => {
        if (step === 1 && selectedDate) {
            setIsLoadingMeetings(true);
            try {
                await initializeBankroll();
                await fetchMeetings();
                setStep(2);
            } catch (error) {
                console.error('Error loading meetings:', error);
                // Optionally show an error message to the user
            } finally {
                setIsLoadingMeetings(false);
            }
        } else if (step === 2 && selectedMeetings.length > 0) {
            handleProcessRaces();
        } else if (step === 3) {
            setStep(4);
        }
    };

    const isNextDisabled = () => {
        if (step === 1) return !selectedDate;
        if (step === 2) return !selectedMeetings?.length || !isBankrollInitialized;
        if (step === 3) return false;
        return false;
    };

    useEffect(() => {
        if (meetings.length > 0) {
            setDisplayedMeetings(meetings.slice(0, meetingsPerPage));
            setHasMore(meetings.length > meetingsPerPage);
        }
    }, [meetings]);

    const observerTarget = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                    loadMore();
                }
            },
            { threshold: 0.1 }
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [hasMore, loading, displayedMeetings]);

    const loadMore = () => {
        if (loading || !hasMore) return;

        setLoading(true);
        const currentLength = displayedMeetings.length;
        const nextMeetings = meetings.slice(
            currentLength,
            currentLength + meetingsPerPage
        );

        setTimeout(() => {
            setDisplayedMeetings(prev => [...prev, ...nextMeetings]);
            setHasMore(currentLength + nextMeetings.length < meetings.length);
            setLoading(false);

            if (currentLength + nextMeetings.length >= meetings.length) {
                setShowCompletion(true);
                setTimeout(() => setShowCompletion(false), 3000);
            }
        }, 500);
    };

    const analyzeBettingStrategy = (predictions: Prediction[]): BetRecommendation[] => {
        if (!predictions || predictions.length === 0) return [];

        // Map all runners, including excluded ones
        return predictions.map(prediction => ({
            runnerNumber: prediction.runnerNumber,
            runnerName: prediction.runnerName,
            silkUrl: prediction.silkUrl,
            jockeyName: prediction.jockeyName,
            ev: prediction.ev || 0,
            probabilityScore: prediction.probabilityScore || 0,
            stabilityScore: prediction.stabilityScore || 0,
            speedRating: prediction.speedRating || 0,
            currentOdds: prediction.currentOdds || 0,
            recommendedStake: prediction.recommendedStake || 0,
            averageFinishPosition: prediction.averageFinishPosition || 0,
            consistencyScore: prediction.consistencyScore || 0,
            classLevelFactor: prediction.classLevelFactor || '',
            potentialProfit: prediction.potentialProfit || 0,
            excluded: prediction.excluded || false,
            betAmount: prediction.recommendedStake || 0,
            expectedReturn: (prediction.currentOdds || 0) * (prediction.recommendedStake || 0),
            recommendedPosition: prediction.averageFinishPosition || 0,
            kellyFraction: prediction.kellyFraction || 0  // Add this line
        }));
    };

    const toggleFilter = (filterId: string) => {
        setActiveFilters((filters) =>
            filters.map((filter) => (filter.id === filterId ? { ...filter, active: !filter.active } : filter))
        );
    };

    const handlePlaceBet = async (race: RacePrediction, bets: any[]) => {
        if (!bankroll || placedBets[race.raceNumber]) return;

        const totalStake = bets.reduce((sum: number, bet: any) => sum + bet.recommendedStake, 0);
        const expectedProfit = bets.reduce(
            (sum: number, bet: any) => sum + bet.potentialProfit * bet.probabilityScore,
            0
        );

        if (totalStake > bankroll.currentAmount) {
            alert('Insufficient bankroll to place these bets.');
            return;
        }

        if (!race.jurisdiction || !race.sellCode) {
            console.error('Missing required fields for bet placement');
            return;
        }

        const payload = {
            jurisdiction: race.jurisdiction,
            sellCode: race.sellCode,
            venueMnemonic: race.venueMnemonic,
            raceNumber: race.raceNumber,
            runnerSelections: bets.map((bet: any) => bet.runnerNumber.toString()).join(','),
            bets: bets.map((bet: any) => {
                const betType = getBetType(bet);
                const amount1 = formatAmount(bet.recommendedStake);
                const amount2 = betType === 'WP' ? formatAmount(bet.recommendedStake / 2) : '00000.0';
                const betString = generateBetString(race, bet, betType, amount1, amount2);

                return {
                    betType: betType,
                    amount: bet.recommendedStake,
                    runnerNumber: bet.runnerNumber,
                    propositionId: bet.propositionId,
                    currentOdds: bet.currentOdds,
                    betString: betString,
                };
            }),
        };

        try {
            const response = await apiService.placeBets(bankroll.bankrollId, payload);
            console.log('Bet placement response:', response);

            setBankroll(prev => prev ? {
                ...prev,
                currentAmount: prev.currentAmount - totalStake
            } : prev);

            setBankrollStats(prev => ({
                totalBets: prev.totalBets + bets.length,
                totalCost: prev.totalCost + totalStake,
                expectedValue: prev.expectedValue + expectedProfit,
                riskLevel: 'Medium',
            }));

            setPlacedBets(prev => ({ ...prev, [race.raceNumber]: true }));
            alert('Bets placed successfully!');
        } catch (error) {
            console.error('Error placing bets:', error);
            alert('Failed to place bets. Please try again.');
        }
    };

    const isStartingSoon = (startTime: string) => {
        const raceTime = new Date(startTime).getTime();
        const now = new Date().getTime();
        const diff = raceTime - now;
        return diff > 0 && diff < 30 * 60 * 1000; // Within 30 minutes
    };

    const filterRaces = (races: RacePrediction[]) => {
        return races.filter((race) => {
            return activeFilters.every((filter) => {
                if (!filter.active) return true;
                const prediction = race.predictions?.[0];
                if (!prediction) return false;

                const value = prediction[filter.field];
                if (value === undefined) return false;

                const numericValue = typeof value === 'number' ? value :
                    typeof value === 'string' ? parseFloat(value) : 0;
                if (filter.min !== undefined && numericValue < filter.min) return false;
                if (filter.max !== undefined && numericValue > filter.max) return false;
                return true;
            });
        });
    };

    const renderRaceResults = () => {
        const races = racePredictions[activeMeetingTab] || [];
        return filterRaces(races)
            .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
            .map((race: RacePrediction) => {
                const predictions = race.predictions || [];
                const bets = analyzeBettingStrategy(predictions);

                if (!bets || !bets.length) return null;

                return (
                    <RaceCard key={`${race.meetingName}-${race.raceNumber}`}>
                        <RaceHeader>
                            <RaceInfo>
                                <h3>
                                    Race {race.raceNumber} - {race.raceName}
                                </h3>
                                <small>Distance: {race.raceDistance}m</small>
                            </RaceInfo>
                            <RaceHeaderActions>
                                <RaceTime startingSoon={isStartingSoon(race.startTime)}>
                                    {format(new Date(race.startTime), 'HH:mm')}
                                </RaceTime>
                                <RaceActionButton
                                    onClick={() => handleAddRace(race)}
                                    disabled={isStartingSoon(race.startTime)}
                                >
                                    <FaPlus /> Add Race
                                </RaceActionButton>
                            </RaceHeaderActions>
                        </RaceHeader>

                        <RunnersGrid>
                            {bets.map((bet, index) => {
                                if (!bet) return null;
                                const isTopPick = index < 4;

                                return (
                                    <RunnerCard key={bet.runnerNumber} rank={index + 1} isRecommended={isTopPick}>
                                        <RunnerIndicators>
                                            {bet.currentOdds === Math.min(...bets.map(b => b.currentOdds)) && (
                                                <RunnerIcon type="favorite">
                                                    <FaStar />
                                                </RunnerIcon>
                                            )    }
                                        </RunnerIndicators>

                                        <SilkImage>
                                            {bet.silkUrl && <img src={bet.silkUrl} alt={`Silk for ${bet.runnerName}`} />}
                                        </SilkImage>

                                        <RunnerHeader>
                                            <div style={{ flex: 1 }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <RunnerNumber>{bet.runnerNumber}</RunnerNumber>
                                                    <RunnerName>{bet.runnerName}</RunnerName>
                                                </div>
                                                {bet.jockeyName && (
                                                    <JockeyInfo>
                                                        <FaUser />
                                                        {bet.jockeyName}
                                                    </JockeyInfo>
                                                )}
                                            </div>
                                        </RunnerHeader>

                                        <TagContainer>
                                            {(() => {
                                                const betType = determineBetType(bet, bets);
                                                return (
                                                    <>
                                                        {betType === 'win' && (
                                                            <BetTypeTag type="win" highlighted={true}>
                                                                <FaTrophy /> Win Bet
                                                            </BetTypeTag>
                                                        )}
                                                        {betType === 'place' && (
                                                            <BetTypeTag type="place" highlighted={true}>
                                                                <FaCheckCircle /> Place Bet
                                                            </BetTypeTag>
                                                        )}
                                                        {bet.stabilityScore > 8 && (
                                                            <BetTypeTag type="hedge">
                                                                Stability: {bet.stabilityScore.toFixed(1)}
                                                            </BetTypeTag>
                                                        )}
                                                    </>
                                                );
                                            })()}
                                        </TagContainer>

                                        <BetDetails>
                                            <BetMetric highlighted={determineBetType(bet, bets) === 'win'}>
                                                <span>EV:</span>
                                                <span>{(bet.ev * 100).toFixed(1)}%</span>
                                            </BetMetric>
                                            <BetMetric highlighted={determineBetType(bet, bets) === 'win'}>
                                                <span>Win:</span>
                                                <span>{(bet.probabilityScore * 100).toFixed(1)}%</span>
                                            </BetMetric>
                                            <BetMetric>
                                                <span>Odds:</span>
                                                <span>${bet.currentOdds.toFixed(2)}</span>
                                            </BetMetric>
                                            <BetMetric highlighted={determineBetType(bet, bets) !== null}>
                                                <span>Stake:</span>
                                                <span>${bet.recommendedStake.toFixed(2)}</span>
                                            </BetMetric>
                                            <BetMetric highlighted={bet.stabilityScore > 8}>
                                                <span>Stability:</span>
                                                <span>{bet.stabilityScore?.toFixed(1)}</span>
                                            </BetMetric>
                                        </BetDetails>

                                        <BetOptions className="bet-options">
                                            <BetOption>
                                                <FaTrophy /> Win
                                            </BetOption>
                                            <BetOption>
                                                <FaCheckCircle /> Place
                                            </BetOption>
                                            <BetOption>
                                                <FaExchangeAlt /> Each Way
                                            </BetOption>
                                            <BetOption>
                                                <FaPlus /> Add
                                            </BetOption>
                                        </BetOptions>
                                    </RunnerCard>
                                );
                            })}
                        </RunnersGrid>

                        <CollaborativeBetting>
                            <BetSplitOption>
                                <strong>
                                    <FaUser /> Friend 1
                                </strong>
                                {bets[0] && (
                                    <>
                                        <BetCombination>
                                            <BetTypeTag type="win" highlighted={true}>
                                                <FaTrophy />
                                            </BetTypeTag>
                                            <BetRunners>{bets[0].runnerName}</BetRunners>
                                            <BetAmount>${bets[0].recommendedStake.toFixed(2)}</BetAmount>
                                        </BetCombination>
                                        {bets[1] && bets[2] && (
                                            <BetCombination>
                                                <BetTypeTag type="trifecta">
                                                    <FaStar />
                                                </BetTypeTag>
                                                <BetRunners>
                                                    {bets[0].runnerName}/{bets[1].runnerName}/{bets[2].runnerName}
                                                </BetRunners>
                                                <BetAmount>${(bets[0].recommendedStake * 0.5).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                        {bets[1] && (
                                            <BetCombination>
                                                <BetTypeTag type="quinella">
                                                    <FaExchangeAlt />
                                                </BetTypeTag>
                                                <BetRunners>
                                                    {bets[0].runnerName}+{bets[1].runnerName}
                                                </BetRunners>
                                                <BetAmount>${(bets[0].recommendedStake * 0.75).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                    </>
                                )}
                            </BetSplitOption>

                            <BetSplitOption>
                                <strong>
                                    <FaUserFriends /> Friend 2
                                </strong>
                                {bets[1] && (
                                    <>
                                        <BetCombination>
                                            <BetTypeTag type="place" highlighted={true}>
                                                <FaCheckCircle />
                                            </BetTypeTag>
                                            <BetRunners>{bets[1].runnerName}</BetRunners>
                                            <BetAmount>${bets[1].recommendedStake.toFixed(2)}</BetAmount>
                                        </BetCombination>
                                        {bets[2] && (
                                            <BetCombination>
                                                <BetTypeTag type="blended">
                                                    <FaMix />
                                                </BetTypeTag>
                                                <BetRunners>
                                                    {bets[1].runnerName}+{bets[2].runnerName}
                                                </BetRunners>
                                                <BetAmount>${(bets[1].recommendedStake * 0.75).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                        {bets[3] && (
                                            <BetCombination>
                                                <BetTypeTag type="hedge">
                                                    <FaShieldAlt />
                                                </BetTypeTag>
                                                <BetRunners>{bets[3].runnerName}</BetRunners>
                                                <BetAmount>${(bets[1].recommendedStake * 0.5).toFixed(2)}</BetAmount>
                                            </BetCombination>
                                        )}
                                    </>
                                )}
                            </BetSplitOption>
                        </CollaborativeBetting>

                        <BetActionBar>
                            <ShareButton onClick={handleShare}>
                                <FaShare /> Share Results
                            </ShareButton>
                            <CopyButton onClick={() => handleGenerateBetStrings(race, bets)}>Copy Bet Strings</CopyButton>
                            {!placedBets[race.raceNumber] ? (
                                <Button primary onClick={() => handlePlaceBet(race, bets)}>
                                    Place Bets (${getTotalBetAmount(bets).toFixed(2)})
                                </Button>
                            ) : (
                                <Button disabled>Bets Placed</Button>
                            )}
                        </BetActionBar>
                    </RaceCard>
                );
            });
    };

    const handleGenerateBetStrings = (race: RacePrediction, bets: any[]) => {
        const betStrings = bets.map((bet) => {
            const betType = getBetType(bet);
            const amount1 = formatAmount(bet.recommendedStake);
            const amount2 = betType === 'WP' ? formatAmount(bet.recommendedStake / 2) : '00000.0';
            const betString = generateBetString(race, bet, betType, amount1, amount2);

            return betString;
        });

        const betStringsText = betStrings.join('\n');

        if (navigator.clipboard) {
            navigator.clipboard.writeText(betStringsText).then(
                () => {
                    alert('Bet strings copied to clipboard!');
                },
                (err) => {
                    console.error('Could not copy bet strings: ', err);
                }
            );
        } else {
            alert('Clipboard not available');
        }
    };

    const getBetType = (bet: any): string => {
        // For simplicity, let's assume bets are 'WIN' if EV > 0, else 'PLACE'
        return bet.ev > 0 ? 'WIN' : 'PLACE';
    };

    const formatAmount = (amount: number): string => {
        // Ensure amount is rounded to nearest 0.5
        amount = Math.round(amount * 2) / 2;
        // Format amount to have five digits before decimal and one after
        const amountStr = amount.toFixed(1);
        const parts = amountStr.split('.');
        const integerPart = parts[0].padStart(5, '0');
        const decimalPart = parts[1];
        return `${integerPart}.${decimalPart}`; // e.g., '00001.0' for $1.0
    };

    const generateBetString = (
        race: RacePrediction,
        bet: any,
        betType: string,
        amount1: string,
        amount2: string
    ): string => {
        // Construct the bet string
        const venueCode = getVenueCode(race.venueMnemonic);
        const raceType = race.raceType;
        const raceNumber = String(race.raceNumber).padStart(2, '0');
        const selections = bet.runnerNumber.toString();

        const betString = `${venueCode}-${raceType}-${raceNumber}-${betType}-${amount1}-${amount2}/${selections}/`;

        return betString;
    };

    const getVenueCode = (venueMnemonic: string): string => {
        // Map venueMnemonic to Venue Code
        const venueCodeMap: { [key: string]: string } = {
            MELB: 'M', // Melbourne
            SYDN: 'S', // Sydney
            BRIS: 'B', // Brisbane
            ADEL: 'A', // Adelaide
            PER: 'W', // Perth
            // Add more mappings as needed
        };
        return venueCodeMap[venueMnemonic] || 'O'; // Default to 'O' if not found
    };

    const getTotalBetAmount = (bets: any[]): number => {
        return bets.reduce((total, bet) => total + bet.recommendedStake, 0);
    };

    const handleCopyAllBetStrings = () => {
        const allBetStrings: string[] = [];

        Object.values(racePredictions).forEach((races) => {
            races.forEach((race) => {
                const bets = analyzeBettingStrategy(race.predictions || []);
                if (bets && bets.length) {
                    bets.forEach((bet) => {
                        const betType = getBetType(bet);
                        const amount1 = formatAmount(bet.recommendedStake);
                        const amount2 = betType === 'WP' ? formatAmount(bet.recommendedStake / 2) : '00000.0';
                        const betString = generateBetString(race, bet, betType, amount1, amount2);
                        allBetStrings.push(betString);
                    });
                }
            });
        });

        const betStringsText = allBetStrings.join('\n');

        if (navigator.clipboard) {
            navigator.clipboard.writeText(betStringsText).then(
                () => {
                    alert('All bet strings copied to clipboard!');
                },
                (err) => {
                    console.error('Could not copy bet strings: ', err);
                }
            );
        } else {
            alert('Clipboard not available');
        }
    };

    const handleCancel = () => {
        setIsCancelling(true);
        // Add any cleanup logic here
        setTimeout(() => {
            setProcessingRaces(false);
            setProgress(0);
            setIsCancelling(false);
        }, 500);
    };

    const checkScrollButtons = useCallback(() => {
        const tabList = document.querySelector('.horizontal-tab-list');
        if (tabList) {
            setShowLeftScroll(tabList.scrollLeft > 0);
            setShowRightScroll(
                tabList.scrollLeft < (tabList.scrollWidth - tabList.clientWidth)
            );
        }
    }, []);

    useEffect(() => {
        const tabList = document.querySelector('.horizontal-tab-list');
        if (tabList) {
            tabList.addEventListener('scroll', checkScrollButtons);
            // Initial check
            checkScrollButtons();
        }
        return () => {
            if (tabList) {
                tabList.removeEventListener('scroll', checkScrollButtons);
            }
        };
    }, [checkScrollButtons]);

    const handleScroll = (direction: 'left' | 'right') => {
        const tabList = document.querySelector('.horizontal-tab-list');
        if (tabList) {
            const scrollAmount = direction === 'left' ? -200 : 200;
            tabList.scrollLeft += scrollAmount;
        }
    };

    const handleTabChange = (meetingName: string) => {
        setActiveMeetingTab(meetingName);
        const tabList = document.querySelector('.horizontal-tab-list');
        const activeTab = document.querySelector(`[data-meeting="${meetingName}"]`);

        if (tabList && activeTab) {
            const tabRect = activeTab.getBoundingClientRect();
            const containerRect = tabList.getBoundingClientRect();

            const scrollLeft = tabList.scrollLeft + tabRect.left - containerRect.left -
                (containerRect.width / 2) + (tabRect.width / 2);

            tabList.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
    };

    // Add function to cycle through meetings
    const cycleMeetings = (direction: 'next' | 'prev') => {
        const meetingNames = Object.keys(racePredictions);
        const currentIndex = meetingNames.indexOf(activeMeetingTab);
        let newIndex;

        if (direction === 'next') {
            newIndex = currentIndex + 1 >= meetingNames.length ? 0 : currentIndex + 1;
        } else {
            newIndex = currentIndex - 1 < 0 ? meetingNames.length - 1 : currentIndex - 1;
        }

        handleTabChange(meetingNames[newIndex]);
    };

    const LoadMoreContainer = styled.div`
        text-align: center;
        padding: 20px;
        margin-bottom: 100px;
        color: #666;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        .stats {
            font-size: 14px;
            padding: 8px 16px;
            background: #f5f5f5;
            border-radius: 8px;
            display: block;
            margin: 0;
        }
    `;

    const LoadMoreButton = styled.button`
        padding: 8px 16px;
        border-radius: 6px;
        border: none;
        cursor: pointer;
        font-weight: 500;
        font-size: 13px;
        color: white;
        background: linear-gradient(135deg, #40B549 0%, #2D8A34 100%);
        box-shadow: 0 2px 4px rgba(64, 181, 73, 0.2);
        transition: all 0.2s ease;
        display: block;
        margin: 0;

        &:hover {
            background: linear-gradient(135deg, #4BC556 0%, #37A03F 100%);
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(64, 181, 73, 0.3);
        }

        &:active {
            transform: translateY(0);
            box-shadow: 0 2px 4px rgba(64, 181, 73, 0.2);
        }

        .spinner {
            display: inline-block;
            width: 12px;
            height: 12px;
            border: 2px solid #ffffff;
            border-top-color: transparent;
            border-radius: 50%;
            margin-right: 6px;
            animation: spin 1s linear infinite;
        }

        @keyframes spin {
            to { transform: rotate(360deg); }
        }
    `;

    const renderMeetings = () => {
        if (!displayedMeetings || !selectedMeetings) return null;

        return displayedMeetings.map((meeting) => {
            if (!meeting) return null;

            const isSelected = selectedMeetings.includes(meeting.meetingName);
            const totalRaces = meeting.races?.length || 0;
            const remainingRaces = meeting.races?.filter(
                (race: any) => new Date(race.raceStartTime) > new Date()
            ).length || 0;
            const progress = (remainingRaces / totalRaces) * 100;
            const venueCode = meeting.venueMnemonic || meeting.meetingName.substring(0, 3).toUpperCase();

            return (
                <MeetingContainer
                    key={meeting.meetingName}
                    isSelected={isSelected}
                    onClick={() => handleMeetingSelect(meeting.meetingName)}
                >
                    <RaceIndicator>
                        <VenueCode>{venueCode}</VenueCode>
                        <RacesRemaining progress={progress}>
                            {remainingRaces}/{totalRaces}
                        </RacesRemaining>
                    </RaceIndicator>

                    <MeetingInfo>
                        <MeetingName isSelected={isSelected}>
                            {meeting.meetingName}
                        </MeetingName>

                        <RaceGrid>
                            {meeting.races
                                ?.filter((race: Race) => new Date(race.raceStartTime) > new Date())
                                .slice(0, 6) // Show up to 6 races instead of 3
                                .map((race: Race) => (
                                    <RaceItem key={`race-${race.raceNumber}`}>
                                        R{race.raceNumber} ({getTimeDisplay(race.raceStartTime)})
                                    </RaceItem>
                                ))}
                        </RaceGrid>
                    </MeetingInfo>

                    <MeetingMetrics>
                        {meeting.weatherCondition && (
                            <StatPill variant={meeting.weatherCondition === 'FINE' ? 'success' : 'warning'}>
                                {meeting.weatherCondition === 'FINE' ? <WiDaySunny size={12} /> : <WiCloudy size={12} />}
                                {meeting.weatherCondition}
                            </StatPill>
                        )}

                        {meeting.trackCondition && (
                            <StatPill variant={meeting.trackCondition.includes('GOOD') ? 'success' : 'warning'}>
                                {meeting.trackCondition}
                            </StatPill>
                        )}

                        {meeting.scratchings?.length > 0 && (
                            <StatPill variant="danger">
                                {meeting.scratchings.length} SCR
                            </StatPill>
                        )}
                    </MeetingMetrics>
                </MeetingContainer>
            );
        });
    };

    // Add these new styled components near the other styled components

    const RunnerImage = styled.div`
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid ${({ theme }) => theme.colors.border};
      margin-right: 12px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `;

    const RunnerMainInfo = styled.div`
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
    `;

    const JockeyInfo = styled.div`
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 11px;
      color: #666;
      margin-top: 2px;
      white-space: nowrap; // Prevent wrapping
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%; // Prevent overflow

      svg {
        color: #40B549;
        font-size: 10px;
        flex-shrink: 0; // Prevent icon from shrinking
      }

      .jockey-name {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;

    const RankIndicator = styled.div<{ rank: number }>`
      position: absolute;
      top: -10px;
      right: -10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      color: white;
      background: ${({ rank }) => {
            switch (rank) {
                case 1:
                    return 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)';
                case 2:
                    return 'linear-gradient(135deg, #C0C0C0 0%, #A0A0A0 100%)';
                case 3:
                    return 'linear-gradient(135deg, #CD7F32 0%, #8B4513 100%)';
                case 4:
                    return 'linear-gradient(135deg, #2196F3 0%, #1976D2 100%)';
                default:
                    return '#666';
            }
        }};
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    `;

    const StatsGrid = styled.div`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
      margin-top: 12px;
    `;

    const StatItem = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
      background: #f8f9fa;
      border-radius: 4px;
      font-size: 11px;

      .label {
        color: #666;
        margin-bottom: 4px;
      }

      .value {
        font-weight: 500;
        color: #333;
      }
    `;

    // Add this near other styled components
    const FavoriteIcon = styled(FaStar)`
      position: absolute;
      top: -12px;
      right: -12px;
      color: gold;
      font-size: 24px;
      filter: drop-shadow(0 2px 2px rgba(0,0,0,0.2));
    `;

    const RunnerIndicators = styled.div`
      position: absolute;
      top: -8px;
      right: 40px; // Adjusted to not overlap with silk
      display: flex;
      gap: 2px;
      align-items: center;
      z-index: 2;
    `;

    const RunnerIcon = styled.div<{ type: 'favorite' | 'stable' | 'probability' }>`
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${({ type }) => {
            switch (type) {
                case 'favorite':
                    return 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)';
                case 'stable':
                    return 'linear-gradient(135deg, #4CAF50 0%, #45a049 100%)';
                case 'probability':
                    return 'linear-gradient(135deg, #2196F3 0%, #1976D2 100%)';
            }
        }};
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      border: 2px solid white;

      svg {
        color: white;
        font-size: 14px;
      }
    `;

    const NOTABLE_JOCKEYS = [
        'JAMES MCDONALD',
        'DAMIEN OLIVER',
        'CRAIG WILLIAMS',
        'HUGH BOWMAN',
        'WILLIAM PIKE',
        'KERRIN MCEVOY',
        'GLEN BOSS',
        'BRETT PREBBLE',
        'NASH RAWILLER',
        'DAMIAN LANE'
    ];

    // Add this helper function to determine bet types
    const determineBetType = (bet: BetRecommendation, allBets: BetRecommendation[]) => {
        // Sort by stability score
        const topStabilityBets = [...allBets]
            .sort((a, b) => (b.stabilityScore || 0) - (a.stabilityScore || 0))
            .slice(0, 2);

        // Find highest odds among stable runners (stability > 8)
        const stableHighOddsBets = allBets
            .filter(b => (b.stabilityScore || 0) > 8)
            .sort((a, b) => (b.currentOdds || 0) - (a.currentOdds || 0))
            .slice(0, 1);

        if (topStabilityBets.map(b => b.runnerNumber).includes(bet.runnerNumber)) {
            return 'win';
        } else if (stableHighOddsBets.map(b => b.runnerNumber).includes(bet.runnerNumber)) {
            return 'place';
        }
        return null;
    };

    // Add these interfaces
    interface ShareableData {
        id: string;
        date: string;
        jurisdiction: string;
        raceType: string;
        meetingSelections: string[];
        predictions: GroupedRacePredictions;
        timestamp: number;
    }

    // Add these helper functions
    const generateShareId = (): string => {
        return 'xxxx-xxxx-xxxx-xxxx'.replace(/[x]/g, () => {
            return (Math.random() * 16 | 0).toString(16);
        });
    };

    const createShareableData = (): ShareableData => {
        return {
            id: generateShareId(),
            date: selectedDate,
            jurisdiction,
            raceType,
            meetingSelections: selectedMeetings,
            predictions: racePredictions,
            timestamp: Date.now()
        };
    };

    // Add these functions to handle sharing
    const handleShare = async () => {
        try {
            const shareableData = createShareableData();

            // Store in localStorage (or you could use your API)
            const existingShares = JSON.parse(localStorage.getItem('sharedResults') || '{}');
            existingShares[shareableData.id] = shareableData;
            localStorage.setItem('sharedResults', JSON.stringify(existingShares));

            // Generate shareable URL
            const shareUrl = `${window.location.origin}/shared/${shareableData.id}`;

            // Try to use native sharing if available
            if (navigator.share) {
                await navigator.share({
                    title: 'Race Predictions',
                    text: 'Check out these race predictions!',
                    url: shareUrl
                });
            } else {
                // Fallback to copying to clipboard
                await navigator.clipboard.writeText(shareUrl);
                alert('Share link copied to clipboard!');
            }
        } catch (error) {
            console.error('Error sharing results:', error);
            alert('Failed to share results. Please try again.');
        }
    };



    const RaceInfo = styled.div`
      flex: 1;
      min-width: 200px; // Prevent too narrow wrapping
    `;

    // Add new styled components
    const RaceHeaderActions = styled.div`
      display: flex;
      align-items: center;
      gap: 12px;
      margin-left: auto; // Push the entire group to the right
    `;

    const RaceActionButton = styled(Button)`
      padding: 8px 12px;
      font-size: 12px;
      min-width: auto;
      background: #40B549;
      color: white;

      &:hover {
        background: #379940;
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    `;

    // Add the API function
    const handleAddRace = async (race: RacePrediction) => {
        try {
            // Transform the race object to match the expected type
            const transformedRace = {
                ...race,
                horseNumber: race.predictions?.[0]?.runnerNumber || 0,
                horseName: race.predictions?.[0]?.runnerName || ''
            };

            const response = await apiService.addRace(transformedRace);
        } catch (error) {
            console.error('Error adding race:', error);
        }
    };

    const TabyLogo = styled.img`
      margin-bottom: 24px;
      animation: ${pulseAndGlow} 2s infinite;
    `;

    // Add these styled components near the other styled components

    const LoadingOverlay = styled.div`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.95);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1000;
      padding: 20px;
    `;

    const ProcessingInfo = styled.div`
      text-align: center;
      margin: 20px 0;
      color: #666;
      font-size: 14px;
    `;

    const LoadingProgressBar = styled.div`
      width: 80%;
      max-width: 400px;
      height: 4px;
      background: #eee;
      border-radius: 2px;
      margin-top: 20px;
      overflow: hidden;
    `;

    const LoadingProgressFill = styled.div<{ progress: number }>`
      height: 100%;
      width: ${({ progress }) => `${progress}%`};
      background: linear-gradient(135deg, #00b09b, #96c93d);
      transition: width 0.3s ease;
    `;

    const ProcessingStats = styled.div`
      display: flex;
      justify-content: center;
      gap: 20px;
      margin: 10px 0;

      span {
        background: #f5f5f5;
        padding: 8px 16px;
        border-radius: 20px;
        font-size: 14px;
        color: #666;
      }
    `;

    // Add these styled components near the other styled components

    const BetOptions = styled.div`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255,255,255,0.95);
      padding: 6px;
      border-top: 1px solid #eee;
      display: flex;
      justify-content: space-around;
      opacity: 0;
      transform: translateY(10px);
      transition: all 0.2s ease;
      border-radius: 0 0 8px 8px;
      font-size: 11px;
    `;

    const BetOption = styled.button`
      background: none;
      border: none;
      padding: 4px 8px;
      color: #666;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: inherit;
      transition: all 0.2s ease;

      &:hover {
        color: #40B549;
      }

      svg {
        font-size: 12px;
      }
    `;

    const BetRunners = styled.span`
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
    `;

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <FaHorse /> Race Selector
                </HeaderTitle>
            </Header>

            <StepIndicator>
                {STEPS.map((stepInfo, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && <StepDivider />}
                        <StepItem
                            active={step >= index + 1}
                            clickable={step > index + 1}
                            onClick={() => step > index + 1 && setStep(index + 1)}
                        >
                            <div className="step-number">{index + 1}</div>
                            <div className="step-label">{stepInfo.label}</div>
                        </StepItem>
                    </React.Fragment>
                ))}
            </StepIndicator>

            <StepDescription>{STEPS[step - 1].description}</StepDescription>

            <ContentWrapper>
                {/* Step Contents */}
                {/* Step 1: Select Date */}
                <StepContent isActive={step === 1}>
                    <FilterSection>
                        <FilterGroup>
                            <FilterLabel>Jurisdiction</FilterLabel>
                            <FilterSelect value={jurisdiction} onChange={(e) => setJurisdiction(e.target.value)}>
                                <option value="VIC">Victoria</option>
                                <option value="NSW">New South Wales</option>
                                <option value="QLD">Queensland</option>
                                <option value="SA">South Australia</option>
                                <option value="WA">Western Australia</option>
                                <option value="TAS">Tasmania</option>
                                <option value="NT">Northern Territory</option>
                                <option value="ACT">ACT</option>
                            </FilterSelect>
                        </FilterGroup>

                        <FilterGroup>
                            <FilterLabel>Race Type</FilterLabel>
                            <RaceTypeGroup>
                                <RaceTypeButton active={raceType === 'R'} onClick={() => setRaceType('R')}>
                                    R
                                </RaceTypeButton>
                                <RaceTypeButton active={raceType === 'H'} onClick={() => setRaceType('H')}>
                                    H
                                </RaceTypeButton>
                                <RaceTypeButton active={raceType === 'G'} onClick={() => setRaceType('G')}>
                                    G
                                </RaceTypeButton>
                            </RaceTypeGroup>
                        </FilterGroup>
                    </FilterSection>

                    <DateGrid>
                        {meetingDates.map((date) => (
                            <DateCard
                                key={date.meetingDate}
                                selected={selectedDate === date.meetingDate}
                                onClick={() => {
                                    setSelectedDate(date.meetingDate);
                                }}
                            >
                                <FaCalendarAlt />
                                <div>{format(new Date(date.meetingDate), 'MMM dd, yyyy')}</div>
                            </DateCard>
                        ))}
                    </DateGrid>
                </StepContent>

                {/* Step 2: Select Meetings */}
                <StepContent isActive={step === 2}>
                    {isLoadingMeetings ? (
                        <LoadingContainer>
                            <LoadingSpinner />
                            <h3>Loading Available Meetings...</h3>
                            <p>Please wait while we fetch the racing schedule</p>
                        </LoadingContainer>
                    ) : (
                        <>
                            {renderMeetings()}
                            {hasMore ? (
                                <LoadMoreContainer ref={observerTarget}>
                                    <p className="stats">
                                        Showing {displayedMeetings.length} of {meetings.length} meetings
                                    </p>
                                    <LoadMoreButton onClick={loadMore} disabled={loading}>
                                        {loading ? (
                                            <>
                                                <span className="spinner" />
                                                Loading...
                                            </>
                                        ) : (
                                            'Load More Meetings'
                                        )}
                                    </LoadMoreButton>
                                </LoadMoreContainer>
                            ) : showCompletion && (
                                <LoadMoreContainer>
                                    <p className="stats">All {meetings.length} meetings loaded</p>
                                </LoadMoreContainer>
                            )}
                        </>
                    )}
                </StepContent>

                {/* Step 3: View Results */}
                <StepContent isActive={step === 3}>
                    <TabListContainer>
                        <ScrollButton
                            direction="left"
                            onClick={() => cycleMeetings('prev')}
                            style={{ left: '4px' }}
                        >
                            <FaChevronLeft />
                        </ScrollButton>

                        <HorizontalTabList className="horizontal-tab-list">
                            {Object.keys(racePredictions).map((meetingName) => (
                                <HorizontalTab
                                    key={meetingName}
                                    data-meeting={meetingName}
                                    active={activeMeetingTab === meetingName}
                                    onClick={() => handleTabChange(meetingName)}
                                >
                                    <span>{meetingName}</span>
                                </HorizontalTab>
                            ))}
                        </HorizontalTabList>

                        <ScrollButton
                            direction="right"
                            onClick={() => cycleMeetings('next')}
                            style={{ right: '4px' }}
                        >
                            <FaChevronRight />
                        </ScrollButton>
                    </TabListContainer>

                    <TabContent>
                        <FilterBar>
                            {activeFilters.map((filter) => (
                                <FilterChip key={filter.id} active={filter.active} onClick={() => toggleFilter(filter.id)}>
                                    {filter.label}
                                </FilterChip>
                            ))}
                        </FilterBar>

                        {renderRaceResults()}
                    </TabContent>
                </StepContent>

                {/* Step 4: Finalize Bets */}
                <StepContent isActive={step === 4}>
                    <div style={{ padding: '40px', textAlign: 'center' }}>
                        <h2>Never Stop Gambling</h2>
                        <p>Thank you for using our Race Selector. Remember, the thrill is in the race!</p>
                        <Button primary onClick={() => setStep(1)}>
                            Start Over
                        </Button>
                    </div>
                </StepContent>
            </ContentWrapper>

            {!processingRaces && (
                <NavigationBar>
                    <Button onClick={() => step > 1 && setStep(step - 1)} disabled={step <= 1}>
                        <FaChevronLeft />
                    </Button>

                    <NavigationSteps>
                        {STEPS.map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    background: step === index + 1 ? '#40B549' : '#ddd',
                                    margin: '0 4px',
                                }}
                            />
                        ))}
                    </NavigationSteps>

                    <Button
                        primary
                        onClick={handleNext}
                        disabled={isNextDisabled() || step >= 4}
                    >
                        <FaChevronRight />
                    </Button>
                </NavigationBar>
            )}


            {processingRaces && (
                <LoadingOverlay>
                    <TabyLogo src="images/taby_small-transformed.png" alt="Taby Logo" />
                    <ProcessingInfo>
                        Calculating predictions for selected meetings...
                    </ProcessingInfo>
                    <LoadingProgressBar>
                        <LoadingProgressFill progress={progress} />
                    </LoadingProgressBar>
                    <ProcessingStats>
                        <span>Processed: {processedRaces}/{totalRaces} races</span>
                        <span>Progress: {Math.round(progress)}%</span>
                    </ProcessingStats>
                    {currentRace && (
                        <ProcessingInfo>
                            Currently processing: {currentRace.meetingName} - Race {currentRace.raceNumber}
                        </ProcessingInfo>
                    )}
                    <CancelButton
                        onClick={handleCancel}
                        disabled={isCancelling}
                    >
                        {isCancelling ? 'Cancelling...' : 'Cancel Processing'}
                    </CancelButton>
                </LoadingOverlay>
            )}

        </Container>
    );
};

export default MeetingDateSelector;
