import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 300px;
    z-index: 4;
`;

const ModalTitle = styled.h2`
    font-size: 1.2em;
    margin: 0 0 15px;
    color: #333;
`;

const ModalActions = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
`;

const ModalButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background 0.3s;

    &:hover {
        background: #228b22;
        color: white;
    }

    &.cancel {
        background: #ccc;
    }

    &.confirm {
        background: #32cd32;
        color: white;
    }
`;

interface ConfirmationModalProps {
    isVisible: boolean;
    title: string;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isVisible, title, message, onConfirm, onCancel }) => {
    if (!isVisible) return null;

    return (
        <ModalOverlay>
            <ModalContent>
                <ModalTitle>{title}</ModalTitle>
                <p>{message}</p>
                <ModalActions>
                    <ModalButton className="cancel" onClick={onCancel}>Cancel</ModalButton>
                    <ModalButton className="confirm" onClick={onConfirm}>Confirm</ModalButton>
                </ModalActions>
            </ModalContent>
        </ModalOverlay>
    );
};

export default ConfirmationModal;
