// src/components/Footer.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { useAuth } from '../../Providers/authContext';



// Footer styling with centered and slightly offset icons
const FooterContainer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background: #2C1F56;
  height: 48px;
  z-index: 1000;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const FooterIcon = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  color: ${({ isSelected }) => (isSelected ? '#fff' : 'rgba(255,255,255,0.7)')};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s;
  padding: 8px 0 6px;

  i {
    font-size: 18px;
    margin-bottom: 1px;
  }
`;

const Icon = styled.i`
  display: block;
  font-size: 24px;
  margin-bottom: 3px;
`;

// Sliding panel and overlay styling
const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

const PanelHeader = styled.div`
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background: transparent;
  border-bottom: 1px solid rgba(255,255,255,0.1);
`;

const MenuContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px 0;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  cursor: pointer;
  font-size: 16px;
  color: rgba(255,255,255,0.95);
  transition: all 0.2s ease;

  i {
    margin-right: 12px;
    width: 20px;
    text-align: center;
  }

  &:hover {
    background-color: rgba(255,255,255,0.1);
    color: white;
  }
`;

const PanelControls = styled.div`
  padding: 10px;
  border-top: 1px solid rgba(255,255,255,0.1);
  background-color: rgba(0,0,0,0.2);
`;

const CloseButton = styled.button`
  padding: 15px;
  background: linear-gradient(90deg, #A12312 0%, #2C1F56 100%);
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: center;

  &:hover {
    background: linear-gradient(90deg, #B13323 0%, #3C2F66 100%);
  }
`;

const ActiveInfo = styled.div`
  padding: 15px;
  background: rgba(255,255,255,0.1);
  color: white;
  margin: 10px;
  border-radius: 4px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 10;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
`;

// Rename the styled Panel component to PanelContainer
const PanelContainer = styled.div<{ isVisible: boolean; isDocked: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: ${({ isDocked }) => (isDocked ? '60px' : '300px')};
  background-color: #2C1F56;
  color: white;
  transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(100%)')};
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.3s forwards;
  display: flex;
  flex-direction: column;
  z-index: 1100;

  @media (max-width: 768px) {
    width: ${({ isDocked }) => (isDocked ? '60px' : '80%')};
  }
`;

// Create the SidePanel component (previously named Panel)
const SidePanel: React.FC<{
  isVisible: boolean;
  isDocked: boolean;
  onClose: () => void;
  onLogout: () => void;
}> = ({ isVisible, isDocked, onClose, onLogout }) => {
  const navigate = useNavigate();

  return (
    <PanelContainer
      isVisible={isVisible}
      isDocked={isDocked}
      onClick={(e) => e.stopPropagation()}
    >
      <PanelHeader>Menu</PanelHeader>

      <MenuContainer>
        <MenuItem onClick={() => navigate('/dashboard')}>
          <i className="fas fa-home" />
          Dashboard
        </MenuItem>
        <MenuItem onClick={() => navigate('/account-settings')}>
          <i className="fas fa-user" />
          My Account
        </MenuItem>
        <MenuItem onClick={() => navigate('/settings')}>
          <i className="fas fa-cog" />
          Settings
        </MenuItem>
        <MenuItem onClick={() => navigate('/betting-history')}>
          <i className="fas fa-history" />
          Betting History
        </MenuItem>
        <MenuItem onClick={() => navigate('/auto-bet-settings')}>
          <i className="fas fa-robot" />
          Auto Bet Settings
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <i className="fas fa-sign-out-alt" />
          Logout
        </MenuItem>
      </MenuContainer>

      <PanelControls>
        <CloseButton onClick={onClose}>
          <i className="fas fa-times" /> Close Panel
        </CloseButton>
      </PanelControls>
    </PanelContainer>
  );
};

// Footer component with account panel and modal handling
const Footer: React.FC = () => {
    const [isPanelVisible, setPanelVisible] = useState(false);
    const [isDocked, setDocked] = useState(false);
    const [selectedFooterItem, setSelectedFooterItem] = useState<string | null>(null);
    const [autoBetEnabled, setAutoBetEnabled] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const navigate = useNavigate();
    const { isAuthenticated, logout } = useAuth();

    const togglePanel = () => {
        if (isPanelVisible && !isDocked) {
            setDocked(true);
            setPanelVisible(false);
        } else {
            setPanelVisible(!isPanelVisible);
            setDocked(false);
        }
    };

    const handleFooterItemClick = (item: string) => {
        setSelectedFooterItem(selectedFooterItem === item ? null : item);
        if (item !== 'autobet') navigate(`/${item}`);
    };

    const toggleAutoBet = () => {
        setAutoBetEnabled(!autoBetEnabled);
        setSelectedFooterItem('autobet');
    };

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <>
            {isAuthenticated && (
                <FooterContainer>
                    <FooterContent>
                        <FooterIcon
                            isSelected={selectedFooterItem === 'dashboard'}
                            onClick={() => handleFooterItemClick('dashboard')}
                        >
                            <Icon className="fas fa-home" />
                            Home
                        </FooterIcon>
                        <FooterIcon
                            isSelected={selectedFooterItem === 'results'}
                            onClick={() => handleFooterItemClick('results')}
                        >
                            <Icon className="fas fa-trophy" />
                            Results
                        </FooterIcon>
                        <FooterIcon
                            isSelected={autoBetEnabled}
                            onClick={toggleAutoBet}
                        >
                            <Icon
                                className={`fas ${autoBetEnabled ? 'fa-toggle-on' : 'fa-toggle-off'}`}
                                style={{ color: autoBetEnabled ? '#32cd32' : '#888' }}
                            />
                            Auto
                        </FooterIcon>
                        <FooterIcon
                            isSelected={false}
                            onClick={togglePanel}
                        >
                            <Icon className="fas fa-bars" />
                            Menu
                        </FooterIcon>
                    </FooterContent>
                </FooterContainer>
            )}

            {isPanelVisible && !isDocked && (
                <Overlay onClick={togglePanel} />
            )}

            <SidePanel
                isVisible={isPanelVisible}
                isDocked={isDocked}
                onClose={togglePanel}
                onLogout={() => setShowLogoutModal(true)}
            />

            <ConfirmationModal
                isVisible={showLogoutModal}
                title="Confirm Logout"
                message="Are you sure you want to log out?"
                onConfirm={handleLogout}
                onCancel={() => setShowLogoutModal(false)}
            />
        </>
    );
};

export default Footer;
