import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import { BASE_URL } from '../config';

interface TokenResponse {
    access_token: string;
    refresh_token: string;
}

class AuthService {
    private axiosInstance: AxiosInstance;
    private accessToken: string | null = null;
    private refreshToken: string | null = null;
    private accessTokenExpiry: dayjs.Dayjs | null = null;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json, text/plain, */*',
            }
        });

        this.loadTokensFromLocalStorage();
        this.setupInterceptors();
    }

    private loadTokensFromLocalStorage() {
        this.accessToken = localStorage.getItem('access_token');
        this.refreshToken = localStorage.getItem('refresh_token');

        const accessTokenExpiry = localStorage.getItem('access_token_expiry');
        if (accessTokenExpiry) {
            this.accessTokenExpiry = dayjs(accessTokenExpiry);
        }
    }

    private saveTokensToLocalStorage(tokens: TokenResponse) {
        this.accessToken = tokens.access_token;
        this.refreshToken = tokens.refresh_token;
        this.accessTokenExpiry = dayjs().add(1, 'hour'); // Set expiry to 1 hour from now

        localStorage.setItem('access_token', tokens.access_token);
        localStorage.setItem('refresh_token', tokens.refresh_token);
        localStorage.setItem('access_token_expiry', this.accessTokenExpiry.toISOString());
    }

    private clearTokens() {
        this.accessToken = null;
        this.refreshToken = null;
        this.accessTokenExpiry = null;

        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token_expiry');
    }

    public async login(username: string, password: string): Promise<void> {
        try {
            const response = await this.axiosInstance.post<TokenResponse>('/accounts/login', { username, password });
            const tokens = response.data;

            if (!tokens.access_token || !tokens.refresh_token) {
                console.error("Tokens not found in response:", tokens);
                throw new Error("Tokens not found in response");
            }

            this.saveTokensToLocalStorage(tokens);
            console.log("Login successful. Tokens saved to localStorage.");
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    }

    private async refreshAccessToken() {
        try {
            const response = await this.axiosInstance.post<TokenResponse>('/accounts/refresh-token', {
                refreshToken: this.refreshToken,
            });
            const tokens = response.data;

            this.saveTokensToLocalStorage(tokens);
        } catch (error) {
            this.clearTokens();
            throw error;
        }
    }

    public async logout() {
        try {
            const response = await this.axiosInstance.post('/accounts/logout');

            if (response.status === 200) {
                console.log(response.data.message);
                this.clearTokens();
            } else {
                console.error('Logout failed: Unexpected response', response);
                throw new Error('Unexpected response from logout API');
            }
        } catch (error) {
            console.error('Logout failed:', error);
            throw error;
        }
    }

    private isAccessTokenExpired() {
        return !this.accessTokenExpiry || dayjs().isAfter(this.accessTokenExpiry);
    }

    private setupInterceptors() {
        this.axiosInstance.interceptors.request.use(
            (config: InternalAxiosRequestConfig) => {
                if (this.accessToken) {
                    (config.headers as Record<string, string>).Authorization = `Bearer ${this.accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        this.axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;

                if (error.response?.status === 401 && !originalRequest._retry && this.refreshToken) {
                    originalRequest._retry = true;
                    try {
                        await this.refreshAccessToken();
                        originalRequest.headers.Authorization = `Bearer ${this.accessToken}`;
                        return this.axiosInstance(originalRequest);
                    } catch (refreshError) {
                        this.clearTokens();
                        return Promise.reject(refreshError);
                    }
                }
                return Promise.reject(error);
            }
        );
    }

    public getAxiosInstance(): AxiosInstance {
        return this.axiosInstance;
    }
}

// Assign the instance to a variable before exporting as the default
const authServiceInstance = new AuthService();
export default authServiceInstance;
