import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { BASE_URL } from '../config';

interface AuthContextType {
    isAuthenticated: boolean;
    loading: boolean;
    user: any | null;
    roles: string[];
    username: string | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    signup: (username: string, email: string, password: string) => Promise<void>;
}

interface DecodedToken {
    sub: string;
    jti: string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string[];
    exp: number;
    aud: string;
    iss: string;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user, setUser] = useState<any | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [roles, setRoles] = useState<string[]>([]);
    const [username, setUsername] = useState<string | null>(null);

    useEffect(() => {
        const storedAccessToken = localStorage.getItem('access_token');
        if (storedAccessToken) {
            setAuthStateFromToken(storedAccessToken);
            setIsAuthenticated(true);
        }
        setLoading(false);
    }, []);

    const setAuthStateFromToken = (token: string) => {
        try {
            const decodedToken = jwtDecode<DecodedToken>(token);
            setIsAuthenticated(true);
            setUsername(decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]);
            setRoles(decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || []);
            setUser(decodedToken);
        } catch (error) {
            console.error("Error decoding token:", error);
            setIsAuthenticated(false);
            setUser(null);
            setRoles([]);
            setUsername(null);
        }
    };

    const login = async (username: string, password: string) => {
        try {
            const response = await fetch(BASE_URL + '/accounts/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) throw new Error('Login failed');

            const data = await response.json();
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);

            setAuthStateFromToken(data.access_token); // Set auth state from token
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = async () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setIsAuthenticated(false);
        setUser(null);
    };

    const signup = async (username: string, email: string, password: string) => {
        try {
            setLoading(true);
            const response = await fetch(BASE_URL + '/accounts/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, email, password }),
            });

            if (!response.ok) throw new Error('Registration failed');

            const data = await response.json();
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);

            setAuthStateFromToken(data.access_token);
        } catch (error) {
            console.error('Signup error:', error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const value = {
        isAuthenticated,
        loading,
        user,
        roles,
        username,
        login,
        logout,
        signup,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw new Error('useAuth must be used within an AuthProvider');
    return context;
};
