import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Providers/authContext';
import { useNavigate, useLocation } from 'react-router-dom';

// Import your logo
const logoUrl = 'https://taby.in/images/taby_small-transformed.png';

// Styled Components
const HeaderContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: white;
    padding: 0;
    height: 60px;
    z-index: 1000;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 25px;
    height: 100%;
    position: relative;
`;

const Logo = styled.img`
    height: 40px;
`;

const BrandText = styled.div`
    font-size: 28px;
    font-weight: 600;
    color: #333;
    margin-left: 15px;
    letter-spacing: 1px;
    position: relative;
    top: 1px;
`;

const TabsContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: auto;
    padding-right: 25px;
`;

const Tab = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    color: ${({ isActive }) => (isActive ? '#32cd32' : '#666')};
    transition: all 0.3s ease;

    &:hover {
        color: #32cd32;
    }
`;

const LiveIndicator = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    .live-dot {
        width: 8px;
        height: 8px;
        background-color: #ff4444;
        border-radius: 50%;
        animation: pulse 2s infinite;
    }

    @keyframes pulse {
        0% { transform: scale(1); opacity: 1; }
        50% { transform: scale(1.2); opacity: 0.8; }
        100% { transform: scale(1); opacity: 1; }
    }
`;

// Header Component
const Header: React.FC = () => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [activeTab, setActiveTab] = useState<string>(location.pathname.slice(1) || 'home');

    if (!isAuthenticated) return null;

    const handleTabClick = (tab: string, path: string) => {
        setActiveTab(tab);
        navigate(path);
    };

    return (
        <HeaderContainer>
            <LogoContainer>
                <Logo src={logoUrl} alt="Taby.in Logo" />
                <BrandText>TABY</BrandText>
            </LogoContainer>
            <TabsContainer>
                <Tab
                    isActive={activeTab === 'live'}
                    onClick={() => handleTabClick('live', '/live')}
                >
                    <LiveIndicator>
                        <div className="live-dot" />
                        Watch Live
                    </LiveIndicator>
                </Tab>
            </TabsContainer>
        </HeaderContainer>
    );
};

export default Header;
