// src/pages/ProviderCreatePage.tsx
import React from 'react';
import styled from 'styled-components';

const CreateProviderContainer = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h3`
    color: #32cd32;
    margin-bottom: 20px;
    text-align: center;
`;

const Label = styled.label`
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    width: 100%;
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #32cd32;
    box-sizing: border-box;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
`;

const Button = styled.button<{ primary?: boolean }>`
    padding: 10px 20px;
    background-color: ${({ primary }) => (primary ? '#32cd32' : '#ddd')};
    color: ${({ primary }) => (primary ? '#fff' : '#333')};
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${({ primary }) => (primary ? '#228b22' : '#bbb')};
    }
`;

const CloseButton = styled(Button)`
    background-color: #ccc;
    color: #333;
`;

interface ProviderCreatePageProps {
    onClose: () => void;
}

const ProviderCreatePage: React.FC<ProviderCreatePageProps> = ({ onClose }) => {
    return (
        <CreateProviderContainer>
            <Title>Add New Provider</Title>
            <Label htmlFor="providerUrl">Provider BASE URL</Label>
            <Input id="providerUrl" type="text" placeholder="https://api.provider.com" />

            <Label htmlFor="accountId">Account Identifier</Label>
            <Input id="accountId" type="text" placeholder="Enter account ID" />

            <Label htmlFor="accountPassword">Account Password</Label>
            <Input id="accountPassword" type="password" placeholder="Enter password" />

            <ButtonContainer>
                <Button>Test Connection</Button>
                <Button primary>Link</Button>
                <CloseButton onClick={onClose}>Close</CloseButton>
            </ButtonContainer>
        </CreateProviderContainer>
    );
};

export default ProviderCreatePage;
