import React from 'react';
import VideoPlayer from '../Components/VideoPlayer/VideoPlayer';

const LiveStreamPage: React.FC = () => {
  const hlsStreamUrl = 'https://skylivetab-new.akamaized.net/hls/live/2038780/sky1/index_extreme.m3u8';

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <VideoPlayer defaultSrc={hlsStreamUrl} />
    </div>
  );
};

export default LiveStreamPage;
