// src/pages/LoginPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../Providers/authContext';
import {
    Background,
    AuthBox,
    Header,
    Title,
    Input,
    ActionButton,
    LinkContainer,
    SmallLink,
    ErrorMessage
} from '../Components/Auth/AuthStyles';

// Main LoginPage Component
const LoginPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLogin = async () => {
        try {
            await login(username, password);
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed: Unable to login. Please check your credentials and try again.');
        }
    };

    return (
        <Background>
            <AuthBox>
                <Header>
                    <Title>Welcome Back</Title>
                </Header>

                <Input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                <ActionButton onClick={handleLogin}>
                    Sign In
                </ActionButton>

                <LinkContainer>
                    <SmallLink onClick={() => navigate('/forgot-password')}>
                        Forgot Password?
                    </SmallLink>
                    <SmallLink onClick={() => navigate('/signup')}>
                        Create Account
                    </SmallLink>
                </LinkContainer>

                {error && (
                    <ErrorMessage>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        {error}
                    </ErrorMessage>
                )}
            </AuthBox>
        </Background>
    );
};

export default LoginPage;
