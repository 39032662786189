import styled from 'styled-components';

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #fff url('/images/taby_big-transformed.png') repeat;
    background-size: 120px;
    padding: 20px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.92);
    }
`;

export const AuthBox = styled.div`
    position: relative;
    background: white;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
    background: linear-gradient(90deg, #A12312 0%, #2C1F56 100%);
    margin: -40px -40px 30px -40px;
    padding: 20px;
    border-radius: 10px 10px 0 0;
    color: white;
`;

export const Logo = styled.img`
    width: 100px;
    max-width: 100%;
    margin-bottom: 15px;
`;

export const Title = styled.h1`
    font-size: 1.8rem;
    color: white;
    margin: 0;
    font-weight: 500;
`;

export const Input = styled.input`
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #f8f8f8;
    color: #333;
    font-size: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;

    &:focus {
        outline: none;
        border-color: #2C1F56;
        background: white;
        box-shadow: 0 0 0 2px rgba(44, 31, 86, 0.1);
    }

    ::placeholder {
        color: #999;
    }
`;

export const LinkContainer = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
`;

export const SmallLink = styled.button`
    background: none;
    border: none;
    color: #666;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s ease;
    padding: 5px;

    &:hover {
        color: #A12312;
    }
`;

export const ErrorMessage = styled.div`
    background: #fff1f0;
    border: 1px solid #A12312;
    color: #A12312;
    border-radius: 5px;
    padding: 12px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;

    svg {
        margin-right: 8px;
        color: #A12312;
    }
`;

export const FormGroup = styled.div`
    margin-bottom: 15px;
`;

export const ActionButton = styled.button`
    width: 100%;
    padding: 12px;
    background: linear-gradient(90deg, #A12312 0%, #2C1F56 100%);
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 0;
    transition: all 0.3s ease;

    &:hover {
        background: linear-gradient(90deg, #B13323 0%, #3C2F66 100%);
        transform: translateY(-1px);
    }

    &:active {
        transform: translateY(0);
    }
`;