import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { format } from 'date-fns';
import { ShareableData, RacePrediction } from '../types';

const PredictionList = styled.div`
    margin-top: 20px;
`;

const PredictionItem = styled.div`
    padding: 15px;
    background: white;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SharedResults: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [data, setData] = useState<ShareableData | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        try {
            const existingShares = JSON.parse(localStorage.getItem('sharedResults') || '{}') as Record<string, ShareableData>;
            if (id && existingShares[id]) {
                setData(existingShares[id]);
            } else {
                setError('Shared results not found');
            }
        } catch (err) {
            setError('Error loading shared results');
        } finally {
            setLoading(false);
        }
    }, [id]);

    if (loading) return <div>Loading shared results...</div>;
    if (error) return <div>{error}</div>;
    if (!data) return <div>No results found</div>;

    return (
        <Container>
            <Header>
                <h1>Shared Race Predictions</h1>
                <div>Shared on {format(data.timestamp, 'PPP')}</div>
            </Header>

            <Details>
                <div>Date: {format(new Date(data.date), 'PPP')}</div>
                <div>Jurisdiction: {data.jurisdiction}</div>
                <div>Race Type: {data.raceType}</div>
            </Details>

            <PredictionList>
                <h2>Predictions</h2>
                {Array.isArray(data.predictions) && data.predictions.map((prediction: RacePrediction, index: number) => (
                    <PredictionItem key={index}>
                        <div>
                            <strong>#{prediction.horseNumber}</strong> {prediction.horseName}
                        </div>
                        {prediction.odds && (
                            <div>Odds: {prediction.odds}</div>
                        )}
                        {prediction.position && (
                            <div>Position: {prediction.position}</div>
                        )}
                    </PredictionItem>
                ))}
            </PredictionList>
        </Container>
    );
};

const Container = styled.div`
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
`;

const Header = styled.div`
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;

    h1 {
        margin: 0 0 10px 0;
    }
`;

const Details = styled.div`
    background: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;

    > div {
        margin: 5px 0;
    }
`;

export default SharedResults;